var sliderScripts = (function($) {
  'use strict';
	function init() {
		bindHomeSlider();
	}

	function destroy(){
		$('.home-slider__container--inner').owlCarousel('destroy');
	}


	function bindHomeSlider(){
		initializeHomePageTopSlider();

		// initialize home page top container slaider
		function initializeHomePageTopSlider() {
			$('.home-slider__container--inner').owlCarousel({
				items: 1,
				loop: true,
				autoplay: true,
				mouseDrag: false,
				touchDrag: false,
				animateOut: 'flipOutX'
			});
		}

		// reset owl carousel autoplayTimeout after user action
		$('.home-slider__container--inner').on('changed.owl.carousel', function() {
			$('.home-slider__container--inner').trigger('stop.owl.autoplay');
			$('.home-slider__container--inner').trigger('play.owl.autoplay');
		});
	}


  return {
    init: init,
    destroy: destroy
  };

})(jQuery);

export default sliderScripts;